<template>
  <div class="main-container">
    <Header />

    <!--<ServicesGrid />-->

    <Cart />

    <BrandCarousel />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from "@/components/Header";
import ServicesGrid from "@/components/ServicesGrid";
import Cart from "../components/Cart";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

import data from "../data/slider.json";
export default {
  data() {
    return {
      data
    };
  },
  components: {
    Header,
    ServicesGrid,
    Cart,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  }
};
</script>
