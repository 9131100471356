<template>
	<div class="quicky-cart-area">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<h2 class="text-center" style="padding: 30px">Listado de solicitudes</h2>
					<form action="javascript:void(0)">
						<div class="table-content table-responsive">
							<div v-if="items.length > 0">
								<table class="table">
									<thead>
										<tr>
											<th class="quicky-product-thumbnail">Items</th>
											<th class="cart-product-name">Plataforma</th>
											<!--<th class="quicky-product-price">Precio unitario</th>
											<th class="quicky-product-quantity">Cantidad</th>
											<th class="quicky-product-quantity">Total</th>-->
											<th class="quicky-product-quantity"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in items" :key="item.id">
											<td>{{ item.item_description }}</td>
											<td>{{ item.plat_id | toPlatformName }}</td>
											<!--<td class="text-right">
												{{ currency }}
												{{ parseFloat(item.item_price).toFixed(2) }}
											</td>
											<td>{{ item.item_quantity }}</td>
											<td class="text-right">
												{{ currency }}
												{{ parseFloat(item.item_price * item.item_quantity).toFixed(2) }}
											</td>-->
											<td>
												<a
													class="delete"
													title="Elimiar Solicitud"
													@click.prevent="deleteItem(item.id)"
													data-toggle="tooltip"
													><i class="material-icons"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
								<div class="row">
									<div class="col-md-5 ml-auto">
										<div class="cart-page-total" style="padding-bottom: 30px">
											<!--<h2>Resumen</h2>
											<ul>
												<li>
													Subtotal
													<span>{{ currency }} {{ subtotal }}</span>
												</li>
												<li>
													IVA ({{ tax }}%)
													<span>{{ currency }} {{ taxAmount }}</span>
												</li>
												<li>
													Total
													<span>{{ currency }} {{ totalAmount }}</span>
												</li>
											</ul>-->
											<!-- <router-link @click="changeStatus" to="#">Confirmar compra</router-link> -->
											<button class="btn ht-btn--round" @click="checkVerified()">
												Confirmar
											</button>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<h3
									class="text-center"
									style="padding-top: 30px"
									v-if="tableLength === ''"
								>
									Cargando solicitudes
								</h3>
								<h3
									class="text-center"
									style="padding-top: 30px"
									v-if="tableLength === 0"
								>
									Aún no haz realizado solicitudes
								</h3>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		components: {},
		data() {
			return {
				config: {
					headers: {
						Authorization: `Bearer ` + sessionStorage.getItem('token'),
					},
				},
				currency: '',
				unitcost: '',
				subtotal: '',
				tax: process.env.VUE_APP_TAX,
				items: [],
				isVerified: false,
				tableLength: '',
			}
		},
		computed: {
			totalAmount() {
				let total = 0
				total = (parseFloat(this.taxAmount) + parseFloat(this.subtotal)).toFixed(2)
				return total
			},
			taxAmount() {
				let tax = 0
				tax = (parseFloat(this.subtotal) * (parseFloat(this.tax) / 100)).toFixed(2)
				return tax
			},
		},
		created() {
			this.getOrder()
		},
		filters: {
			toPlatformName: function (platId) {
				if (!platId) return ''
				let platformName
				switch (platId) {
					case 2:
						platformName = 'PayForU'
						break
					case 3:
						platformName = 'MallLikeU'
						break
					case 4:
						platformName = 'Willi-U'
						break
					case 5:
						platformName = 'Ruta-U Negocios'
						break
					case 17:
						platformName = 'Ruta-U Agentes'
						break
					case 6:
						platformName = 'Lili-U'
						break
					case 7:
						platformName = 'U-Konga'
						break
					case 8:
						platformName = 'Euro-ComEx'
						break
					case 9:
						platformName = 'MallLikeU-B2B'
						break
					case 10:
						platformName = 'MallLikeU-B2C'
						break
					case 11:
						platformName = 'MallLikeU-B2C'
						break
					case 18:
						platformName = 'Farma-U'
						break
					case 19:
						platformName = 'Pets-U'
						break
					case 20:
						platformName = 'LikeU-Creative'
						break
					default:
						platformName = platId
						break
				}
				return platformName
			},

			toServiceName(servId) {
				if (!servId) return ''
				let serviceName
				axios
					.get(proxyurl + process.env.VUE_APP_STORE_API + 'api/services/' + servId, {
						headers: {
							Authorization: `Bearer ` + sessionStorage.getItem('token'),
						},
					})
					.then((response) => {
						serviceName = response.data.service.serv_name
						console.log(response.data)
					})
					.catch((error) => {
						serviceName = servId
						console.log(error.response)
						this.error = true
					})
				return serviceName
			},
		},
		methods: {
			async deleteItem(itemId) {
				this.$swal({
					position: 'center',
					title: '¿Desea eliminar?',
					confirmButtonColor: '#41b882',
					cancelButtonColor: '#ff7674',
					showCancelButton: true,
					icon: 'info',
					confirmButtonText: 'Si',
					cancelButtonText: 'No',
					showCloseButton: true,
					showLoaderOnConfirm: true,
				}).then((result) => {
					if (result.value) {
						axios
							.delete(process.env.VUE_APP_CART_API + 'api/items/' + itemId, this.config)
							.then(() => {
								this.getOrder()
								this.$swal({
									position: 'center',
									title: 'Solicitud eliminada',
									showConfirmButton: false,
									timer: 1500,
									icon: 'success',
								})
							})
							.catch((error) => {
								this.$swal({
									position: 'center',
									title: 'Ocurrió un error',
									html:
										'<h3 class="text-center">Compruebe su conexión a internet e intente nuevamente</h3>',
									icon: 'error',
									showConfirmButton: true,
									confirmButtonText: 'Aceptar',
								}).then((result) => {
									if (result.isConfirmed) {
										location.reload()
									}
								})
								this.$swal.showValidationMessage(`Solicitud rechazada: ${error}`)
								console.log(error.response)
								this.error = true
							})
					}
				})
			},
			async getOrder() {
				this.config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token')
				if (sessionStorage.getItem('order') != 0) {
					let orderToken = JSON.parse(sessionStorage.getItem('order')).orde_token
					axios
						.get(process.env.VUE_APP_CART_API + 'api/orders/' + orderToken, this.config)
						.then((response) => {
							sessionStorage.removeItem('order')
							sessionStorage.removeItem('items')
							sessionStorage.setItem('order', JSON.stringify(response.data.order))
							sessionStorage.setItem('items', response.data.order.items.length)
							this.currency = response.data.order.orde_currency
							this.items = response.data.order.items
							this.subtotal = parseFloat(
								JSON.parse(sessionStorage.getItem('order')).orde_total
							).toFixed(2)
							if (response.data.order) {
								this.tableLength = response.data.order.items.length
							}
						})
						.catch((error) => {
							console.log(error)
							this.error = true
						})
				}
			},

			async checkVerified() {
				let urlCheckVerified = process.env.VUE_APP_AUTH_API + 'api/verification/user'
				axios
					.get(urlCheckVerified, this.config)
					.then((response) => {
						//console.log(response.data);
						if (
							response.data.verification == 'to be checked' ||
							response.data.verification == 'verified'
						) {
							this.isVerified = true
							this.changeStatus()
						} else {
							this.isVerified = false
							this.$swal({
								imageUrl: 'https://vistapointe.net/images/lock-4.jpg',
								imageWidth: 200,
								title: `¡Hola ` + sessionStorage.getItem('name') + `!`,
								html: `Antes de continuar, iniciaremos un proceso rutinario de verificación de datos. <br>
	               Esto nos permite mantener una relacion más segura contigo`,
								showCancelButton: false,
								confirmButtonColor: '#003d74',
								showConfirmButton: true,
								confirmButtonText: 'Aceptar',
							}).then((result) => {
								if (result.isConfirmed) {
									this.$router.push({
										name: 'VerifyUser',
										query: {
											email: response.data.email,
										},
									})
								}
							})
						}
						return this.isVerified
					})
					.catch((error) => {
						this.$swal({
							title: 'Parece que ocurrió un error...',
							text: 'Comprueba tu conexión de internet e intenta de nuevo',
							showCancelButton: false,
							showConfirmButton: true,
						}).then((result) => {
							if (result.isConfirmed) {
								location.reload()
							}
						})
						this.$swal.showValidationMessage(error)
						console.log(error)
						this.error = true
					})
			},

			async changeStatus() {
				axios
					.get(
						process.env.VUE_APP_CART_API +
							'api/orders/' +
							JSON.parse(sessionStorage.getItem('order')).orde_token +
							'/status/to pay',
						this.config
					)
					.then((response) => {
						sessionStorage.removeItem('order')
						sessionStorage.removeItem('items')
						this.$router.push('/confirmar')
					})
					.catch((error) => {
						console.log(error.response)
						this.error = true
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/main.scss';

	.quicky-cart-area {
		padding: 20px 0;
	}

	.ht-btn--round {
		padding: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-top: 20px;
		font-size: 20px;
		background-color: #e94e1b;
	}

	.table-content table td {
		border-top: medium none;
		padding: 18px 10px;
		vertical-align: middle;
		font-size: 17px;
	}

	.table-content table th {
		font-size: 18px;
		font-weight: bold;
	}
</style>
